import { useMsal } from '@azure/msal-react';

const useCurrentUser = () => {
  const { accounts } = useMsal();
  if (accounts.length > 0) {
    const account = accounts[0];
    const user = {
      sub: account.idTokenClaims?.sub,
      familyName: account.idTokenClaims?.family_name,
      givenName: account.idTokenClaims?.given_name,
      name: account?.name,
      username: account?.username,
    };
    return user;
  }
  return null;
};

export default useCurrentUser;