import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import BasicModal from '../components/BasicModal';
import Scanner from '../components/Scanner';
import '../assets/BarcodeReader.css';

export default function BarcodeReader() {
  const [open, setOpen] = useState(false);
  const [camera, setCamera] = useState(false);
  const [result, setResult] = useState('');

  const handleOpen = () => {
    setCamera(false);

    if (result === '') {
      alert('JANコードを入力してください');
      return;
    }

    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const onDetected = result => {
    setResult(result);
  };

  const handleChange = (event) => {
    setResult(event.target.value);
  };

  return (
    <>
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
        <Box p={2}>
          <Box mb={1}>
            <Typography component="h5" variant="h5">バーコード検索</Typography>
          </Box>
          <Box mb={1}>
            <TextField id="outlined-basic" label="JANコード" variant="outlined" value={result} onChange={handleChange} />
          </Box>
          <Box mb={1}>
            <Button variant="contained" startIcon={<SearchIcon />} onClick={handleOpen}>検索</Button>
          </Box>
          <Box mb={1}>
            <Button variant="contained" startIcon={camera ? <CancelIcon /> : <CameraAltIcon />} onClick={() => setCamera(!camera)}>
              {camera ? 'スキャン停止' : 'スキャン開始'}
            </Button>
          </Box>
        </Box>
      </Paper>
      <Box className="container">
        {camera && <Scanner onDetected={onDetected} />}
      </Box>
      <BasicModal open={open} page={'reader'} result={result} handleClose={handleClose} />
    </>
  );
}
