import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import ListAltIcon from '@mui/icons-material/ListAlt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useMsal, useAccount } from '@azure/msal-react';
import { protectedResources } from '../utils/authConfig';
import { callApiWithToken } from '../utils/fetch';
import { openNewWindows } from "../utils/openNewWindows";
import { sorts } from '../utils/constant';

export default function SearchPriceForm(props) {
  const { keyword, sort, onSale, setLoading, setItems, setServ, setValue, setSort, setOnSale } = props;

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const handleChangeOnSale = () => {
    setOnSale(prevState => !prevState);
  };

  const handleChangeSort = (event) => {
    setSort(event.target.value);
  };

  const searchPrice = (serv) => {
    setLoading(true);
    if (!keyword) {
      alert('検索ワードを入力してください');
      setLoading(false);
      return;
    }
    const param = { keyword: keyword, serv: serv, sort: sort };
    const query = new URLSearchParams(param);

    const options = {
      method: 'GET',
    };
    if (account && inProgress === 'none') {
      instance.acquireTokenSilent({
          scopes: protectedResources.apiHello.scopes,
          account: account
      }).then((response) => {
          callApiWithToken(response.accessToken, `/api/search-price?${query}`, options)
            .then((data) => {
              setItems(data);
              setServ(serv);
              setValue(1);
              setLoading(false);
            }).catch((error) => {
              console.error('Error:', error);
              alert('価格検索失敗');
              setLoading(false);
            });
      }).catch((error) => {
          console.error('Error:', error);
          alert('認証失敗、再度サインインしてください');
          setLoading(false);
      });
    }
  }
  
  const searchPriceNw = (serv) => {
    if (!keyword) {
      alert('検索ワードを入力してください');
      setLoading(false);
      return;
    }
    openNewWindows(serv, keyword, onSale, sort);
  }

  return (
    <>
      <Box mb={1} display="flex" justifyContent="center">
        <FormControlLabel control={<Switch checked={onSale} onChange={handleChangeOnSale} />} label="販売中のみ" />
      </Box>
      <Box mb={1} display="flex" justifyContent="center">
        <FormControl style={{ width: 200 }}>
          <InputLabel id="demo-simple-select-label">並び順</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={sort}
            label="並び順"
            onChange={handleChangeSort}
          >
            {sorts.map((sort, idx) => <MenuItem value={sort.value} key={idx}>{sort.name}</MenuItem>)}
          </Select>
        </FormControl>
      </Box>
      <Box mb={1} display="flex" justifyContent="center">
        <Button variant="contained" startIcon={<OpenInNewIcon />} onClick={() => searchPriceNw('mc')} style={{ width: 200 }}>メルカリ</Button>
      </Box>
      <Box mb={1} display="flex" justifyContent="center">
        <Button variant="contained" startIcon={<OpenInNewIcon />} onClick={() => searchPriceNw('pf')} style={{ width: 200 }}>PayPayフリマ</Button>
      </Box>
      <Box mb={1} display="flex" justifyContent="center">
        <Button variant="contained" startIcon={<ListAltIcon />} disabled={onSale} onClick={() => searchPrice('rt')} style={{ width: 200 }}>楽天市場</Button>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button variant="contained" startIcon={<ListAltIcon />} disabled={onSale || sort === '31'} onClick={() => searchPrice('yh')} style={{ width: 200 }}>YAHOO!ショッピング</Button>
      </Box>
    </>
  );
}
