import * as React from 'react';
import { useMsal } from '@azure/msal-react';
import { useLocation, useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { site, menus } from '../utils/constant';

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const { onClose, isSmUp, ...other } = props;
  const { instance } = useMsal();
  const location = useLocation();
  const navigate = useNavigate();
  // const signOut = () => {
  //   window.location.href = '/.auth/logout?post_logout_redirect_uri=/';
  // };

  const navi = (path) => {
    if (!isSmUp) {
      onClose();
    }
    navigate(path);
  }

  return (
    <Drawer onClose={onClose} {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          {site}
        </ListItem>
        <ListItem selected={location.pathname === '/'} sx={{ ...item, ...itemCategory }} onClick={() => navi('/')}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText>ホーム</ListItemText>
        </ListItem>
        {menus.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, path, icon }) => {
              if (childId === 'サインアウト') {
                return (
                  <ListItem disablePadding key={childId}>
                    <ListItemButton sx={item} onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: '/' })}>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText>{childId}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                );
              } else if (childId === '検索設定(工事中)') {
                return (
                  <ListItem disablePadding key={childId}>
                    <ListItemButton sx={item} disabled>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText>{childId}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                );
              } else {
                return (
                  // <NavLink to={path} style={({ isActive }) => (isActive ? { textDecoration: 'underlined' } : { textDecoration: 'none' })} key={childId}>
                    <ListItem disablePadding key={childId}>
                      <ListItemButton selected={location.pathname === path} sx={item} onClick={() => navi(path)}>
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText>{childId}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  // </NavLink>
                );
              }
            })}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
