import Chip from '@mui/material/Chip';
import AbcIcon from '@mui/icons-material/Abc';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CategoryIcon from '@mui/icons-material/Category';
import FeedbackIcon from '@mui/icons-material/Feedback';
import LogoutIcon from '@mui/icons-material/Logout';
// import SettingsIcon from '@mui/icons-material/Settings';
// import TimerIcon from '@mui/icons-material/Timer';

export const site = '価格調査アプリ';
export const from = 'info@kakakuchosa.eeuapps.com';
export const menus = [
  {
    id: 'アプリ',
    children: [
      {
        id: 'バーコード検索',
        path: '/barcode',
        icon: <CameraAltIcon />,
        // active: true,
      },
      { id: 'フリーワード検索', path: '/freeword', icon: <AbcIcon /> },
      { id: '商品一覧', path: '/itemlist', icon: <CategoryIcon /> },
      // { id: '検索設定(工事中)', path: '/s', icon: <SettingsIcon /> },
    ],
  },
  {
    id: 'アカウント',
    children: [
      { id: 'プロフィール', path: '/profile', icon: <AccountCircleIcon /> },
      // { id: 'プラン', path: '/plan', icon: <TimerIcon /> },
      { id: 'フィードバック', path: '/feedback', icon: <FeedbackIcon /> },
      { id: 'サインアウト', icon: <LogoutIcon /> },
    ],
  },
];

export const plans = [
  {
    id: 'free',
    chip: <Chip label="FREE" color="success" />,
    price: '0',
    description: [
      'バーコード検索 100回/月',
      'フリーワード検索 100回/月',
      'ECサイト価格検索 100回/月',
      '商品保存数 100個',
      '利益計算機能',
    ],
    buttonText: '無料で始める',
    buttonVariant: 'outlined',
    star: true,
  },
  {
    id: 'standard',
    chip: <Chip label="STANDARD" color="info" />,
    price: '400',
    description: [
      'バーコード検索 1,000回/月',
      'フリーワード検索 1,000回/月',
      'ECサイト価格検索 1,000回/月',
      '商品保存数 1,000個',
      '利益計算機能',
    ],
    buttonText: '始める',
    buttonVariant: 'outlined',
    star: false,
  },
  {
    id: 'premium',
    chip: <Chip label="PREMIUM" color="warning" />,
    price: '1000',
    description: [
      'バーコード検索 無制限',
      'フリーワード検索 無制限',
      'ECサイト価格検索 無制限',
      '商品保存数 無制限',
      '利益計算機能',
    ],
    buttonText: '始める',
    buttonVariant: 'outlined',
    star: false,
  },
];

export const categories = [
  { value: '0', name: '選択してください' },
  { value: '1', name: '操作方法' },
  { value: '2', name: '不具合報告' },
  { value: '3', name: '機能追加・改善要望' },
  { value: '9', name: 'その他' },
];

export const sorts = [
  { value: '0', name: '指定なし' },
  { value: '11', name: '価格が安い順' },
  { value: '12', name: '価格が高い順' },
  { value: '21', name: '人気が高い順' },
  { value: '31', name: '日付が新しい順' },
];

export const servNames = [
  { serv: 'rt', str: '楽天市場' },
  { serv: 'yh', str: 'YAHOO!ショッピング' },
]

export const baseUrls = [
  { serv: 'mc', str: 'https://jp.mercari.com/search?keyword=' },
  { serv: 'pf', str: 'https://paypayfleamarket.yahoo.co.jp/search/' },
]

export const paramPref = [
  { serv: 'mc', str: '&' },
  { serv: 'pf', str: '?' },
]

export const onSaleParamStrs = [
  { serv: 'mc', str: 'status=on_sale' },
  { serv: 'pf', str: 'open=1' },
]

export const sortParamStrs = [
  { serv: 'mc', strs: [
    { sort: '11', str: 'sort=price&order=asc' },
    { sort: '12', str: 'sort=price&order=desc' },
    { sort: '21', str: 'sort=num_likes&order=desc' },
    { sort: '31', str: 'sort=created_time&order=desc' },
  ] },
  { serv: 'pf', strs: [
    { sort: '11', str: 'sort=price&order=asc' },
    { sort: '12', str: 'sort=price&order=desc' },
    { sort: '21', str: 'sort=likeCounts&order=desc' },
    { sort: '31', str: 'sort=openTime&order=desc' },
  ] },
];
