import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import { useMsal, useAccount } from '@azure/msal-react';
// import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources } from '../utils/authConfig';
import { callApiWithToken } from '../utils/fetch';
import ProfitCalculator from './ProfitCalculator';
import SearchResult from './SearchResult';
import SearchPriceForm from './SearchPriceForm';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FreewordContent(props) {
  const { open, page, params, reloadItems } = props;
  const [name, setName] = useState('');
  const [memo, setMemo] = useState('');
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [amounts, setAmounts] = useState({
    selling: 0,
    // cost: 0,
    postage: 0,
    chargeRate: 10,
    charge: 0,
    other: 0,
    profit: 0,
    percent: 0,
  });
  const [items, setItems] = useState([]);
  const [serv, setServ] = useState('');
  const [onSale, setOnSale] = useState(false);
  const [sort, setSort] = useState('0');

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const saveItem = () => {
    setLoading(true);
    const options = {
      method: 'POST',
      body: JSON.stringify({
        page: page,
        params: params,
        data: {
          name: name,
          memo: memo,
          amounts: amounts,
        },
      }),
    };

    if (account && inProgress === 'none') {
      instance.acquireTokenSilent({
          scopes: protectedResources.apiHello.scopes,
          account: account
      }).then((response) => {
          callApiWithToken(response.accessToken, '/api/save-item-freeword', options)
            .then(async (data) => {
              if (page === 'list') {
                await reloadItems();
              }
              setName(data.name);
              setMemo(data.memo);
              setAmounts(data.amounts);
              alert('保存成功');
              setLoading(false);
            }).catch((error) => {
              console.error('Error:', error);
              alert('保存失敗');
              setLoading(false);
            });
      }).catch((error) => {
        console.error('Error:', error);
        alert('認証失敗、再度サインインしてください');
        setLoading(false);
      });
    }
  }

  useEffect(() => {
    if (open) {
      setLoading(true);

      if (page === 'list') {
        const options = {
          method: 'POST',
          body: JSON.stringify(params),
        };
    
        if (account && inProgress === 'none') {
          instance.acquireTokenSilent({
              scopes: protectedResources.apiHello.scopes,
              account: account
          }).then((response) => {
              callApiWithToken(response.accessToken, '/api/item-info', options)
                .then((data) => {
                  setName(data.name);
                  setMemo(data.memo);
                  setAmounts(data.amounts);
                  setLoading(false);
                }).catch((error) => {
                  console.error('Error:', error);
                  alert('商品が見つかりません');
                  setLoading(false);
                });
          }).catch((error) => {
            console.error('Error:', error);
            alert('認証失敗、再度サインインしてください');
            setLoading(false);
          });
        }
      } else {
        console.error('Error:page不正');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChangeTabs} aria-label="basic tabs example">
          <Tab label="価格検索" {...a11yProps(0)} />
          <Tab label="検索結果" {...a11yProps(1)} />
          <Tab label="利益計算" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} style={{ maxHeight: '350px', overflow: 'scroll' }}>
        {loading ?
          <Box display="flex" justifyContent="center"> 
            <CircularProgress />
          </Box>
        :
          <>
            <Box mb={1}>
              <Box mb={2} display="flex" justifyContent="center"><TextField id="outlined-basic" label="検索ワード" variant="outlined" value={name} onChange={handleChange} /></Box>
              <Divider />
            </Box>
            <SearchPriceForm keyword={name} sort={sort} onSale={onSale} setLoading={setLoading} setItems={setItems} setServ={setServ} setValue={setValue} setSort={setSort} setOnSale={setOnSale} />
          </>
        }
      </TabPanel>
      <TabPanel value={value} index={1} style={{ maxHeight: '350px', overflow: 'scroll' }}>
        {loading ?
            <Box display="flex" justifyContent="center"> 
              <CircularProgress />
            </Box>
          :
            <SearchResult items={items} serv={serv} sort={sort} />
        }
      </TabPanel>
      <TabPanel value={value} index={2} style={{ maxHeight: '350px', overflow: 'scroll' }}>
        {loading ?
            <Box display="flex" justifyContent="center"> 
              <CircularProgress />
            </Box>
          :
            <ProfitCalculator amounts={amounts} memo={memo} setAmounts={setAmounts} setMemo={setMemo} />
        }
      </TabPanel>
      {(!loading && name && value !== 1) &&
        <>
          <Divider />
          <Box mt={1} display="flex" justifyContent="center"><Button variant="contained" startIcon={<SaveIcon />} onClick={saveItem}>保存</Button></Box>
        </>
      }
    </>
  );
}
