import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

export default function ProfitCalculator(props) {
  const { amounts, memo, setAmounts, setMemo } = props;

  const handleChange = (event) => {
    setMemo(event.target.value);
  };

  const handleChangeAmounts = (prop) => (event) => {
    switch (prop) {
      case 'selling':
        setAmounts({
          ...amounts,
          selling: Number(event.target.value),
          charge: Math.round(event.target.value * amounts.chargeRate / 100),
          // profit: Number(event.target.value - amounts.cost - amounts.postage - Math.round(event.target.value * amounts.chargeRate / 100) - amounts.other),
          // percent: Math.round((event.target.value - amounts.cost - amounts.postage - Math.round(event.target.value * amounts.chargeRate / 100) - amounts.other) * 100 / event.target.value),
          profit: Number(event.target.value - amounts.postage - Math.round(event.target.value * amounts.chargeRate / 100) - amounts.other),
          percent: Math.round((event.target.value - amounts.postage - Math.round(event.target.value * amounts.chargeRate / 100) - amounts.other) * 100 / event.target.value),
        });
        break;
      // case 'cost':
      //   setAmounts({
      //     ...amounts,
      //     cost: Number(event.target.value),
      //     profit: Number(amounts.selling - event.target.value - amounts.postage - Math.round(amounts.selling * amounts.chargeRate / 100) - amounts.other),
      //     percent: Math.round((amounts.selling - event.target.value - amounts.postage - Math.round(amounts.selling * amounts.chargeRate / 100) - amounts.other) * 100 / amounts.selling),
      //   });
      //   break;
      case 'postage':
        setAmounts({
          ...amounts,
          postage: Number(event.target.value),
          // profit: Number(amounts.selling - amounts.cost - event.target.value - Math.round(amounts.selling * amounts.chargeRate / 100) - amounts.other),
          // percent: Math.round((amounts.selling - amounts.cost - event.target.value - Math.round(amounts.selling * amounts.chargeRate / 100) - amounts.other) * 100 / amounts.selling),
          profit: Number(amounts.selling - event.target.value - Math.round(amounts.selling * amounts.chargeRate / 100) - amounts.other),
          percent: Math.round((amounts.selling - event.target.value - Math.round(amounts.selling * amounts.chargeRate / 100) - amounts.other) * 100 / amounts.selling),
        });
        break;
      case 'chargeRate':
        setAmounts({
          ...amounts,
          chargeRate: Number(event.target.value),
          charge: Math.round(amounts.selling * Number(event.target.value) / 100),
          // profit: Number(amounts.selling - amounts.cost - event.target.value - Math.round(amounts.selling * Number(event.target.value) / 100) - amounts.other),
          // percent: Math.round((amounts.selling - amounts.cost - event.target.value - Math.round(amounts.selling * Number(event.target.value) / 100) - amounts.other) * 100 / amounts.selling),
          profit: Number(amounts.selling - event.target.value - Math.round(amounts.selling * Number(event.target.value) / 100) - amounts.other),
          percent: Math.round((amounts.selling - event.target.value - Math.round(amounts.selling * Number(event.target.value) / 100) - amounts.other) * 100 / amounts.selling),
        });
        break;
      case 'other':
        setAmounts({
          ...amounts,
          other: Number(event.target.value),
          // profit: Number(amounts.selling - amounts.cost - amounts.postage - Math.round(amounts.selling * amounts.chargeRate / 100) - event.target.value),
          // percent: Math.round((amounts.selling - amounts.cost - amounts.postage - Math.round(amounts.selling * amounts.chargeRate / 100) - event.target.value) * 100 / amounts.selling),
          profit: Number(amounts.selling - amounts.postage - Math.round(amounts.selling * amounts.chargeRate / 100) - event.target.value),
          percent: Math.round((amounts.selling - amounts.postage - Math.round(amounts.selling * amounts.chargeRate / 100) - event.target.value) * 100 / amounts.selling),
        });
        break;
      default:
        console.error('Error:不正入力');
    }
  };

  return (
    <Box mb={1} display="flex" justifyContent="center">
      <Box>
        <Box display="flex" justifyContent="center">
          <TextField
            label="売値"
            id="outlined-start-adornment"
            sx={{ m: 1, maxWidth: '190px' }}
            InputProps={{
              startAdornment: <InputAdornment position="start">￥</InputAdornment>,
            }}
            value={amounts.selling}
            type="number"
            onChange={handleChangeAmounts('selling')}
          />
        </Box>
        {/* <Box display="flex" justifyContent="flex-start">
          <div style={{ display: 'inline-block', lineHeight: '60px', marginRight: '6px' }}>－</div>
          <TextField
            label="仕入値"
            id="outlined-start-adornment"
            sx={{ m: 1, maxWidth: '190px' }}
            InputProps={{
              startAdornment: <InputAdornment position="start">￥</InputAdornment>,
            }}
            value={amounts.cost}
            type="number"
            onChange={handleChangeAmounts('cost')}
          />
        </Box> */}
        <Box display="flex" justifyContent="flex-start">
          <div style={{ display: 'inline-block', lineHeight: '60px', marginRight: '6px' }}>－</div>
          <TextField
            label="送料"
            id="outlined-start-adornment"
            sx={{ m: 1, maxWidth: '190px' }}
            InputProps={{
              startAdornment: <InputAdornment position="start">￥</InputAdornment>,
            }}
            value={amounts.postage}
            type="number"
            onChange={handleChangeAmounts('postage')}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <TextField
            label="販売手数料率"
            id="outlined-end-adornment"
            sx={{ m: 1, maxWidth: '190px' }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            value={amounts.chargeRate}
            type="number"
            size='small'
            onChange={handleChangeAmounts('chargeRate')}
          />
        </Box>
        <Box display="flex" justifyContent="flex-start">
          <div style={{ display: 'inline-block', lineHeight: '60px', marginRight: '6px' }}>－</div>
          <FormControl sx={{ m: 1, maxWidth: '190px' }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">販売手数料</InputLabel>
            <Input
              id="standard-adornment-amount"
              startAdornment={<InputAdornment position="start">￥</InputAdornment>}
              value={amounts.charge}
              type="number"
              disabled
            />
          </FormControl>
        </Box>
        <Box display="flex" justifyContent="flex-start">
          <div style={{ display: 'inline-block', lineHeight: '60px', marginRight: '6px' }}>－</div>
          <TextField
            label="その他費用"
            id="outlined-start-adornment"
            sx={{ m: 1, maxWidth: '190px' }}
            InputProps={{
              startAdornment: <InputAdornment position="start">￥</InputAdornment>,
            }}
            value={amounts.other}
            type="number"
            onChange={handleChangeAmounts('other')}
          />
        </Box>
        <Box display="flex" justifyContent="flex-start">
          <div style={{ display: 'inline-block', lineHeight: '60px', marginRight: '6px' }}>＝</div>
          <TextField
            label="利益"
            id="filled-start-adornment"
            sx={{ m: 1, maxWidth: '190px' }}
            InputProps={{
              startAdornment: <InputAdornment position="start">￥</InputAdornment>,
            }}
            variant="filled"
            value={amounts.profit}
            type="number"
            disabled
          />
        </Box>
        <Box mb={2} display="flex" justifyContent="center">
          <TextField
            label="利益率（利益 / 売値 × 100）"
            id="filled-end-adornment"
            sx={{ m: 1, maxWidth: '190px' }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            variant="filled"
            value={amounts.percent}
            type="number"
            disabled
          />
        </Box>
        <Box>
          <TextField
            id="outlined-multiline-static"
            label="メモ"
            multiline
            rows={3}
            fullWidth
            value={memo}
            onChange={handleChange}
          />
        </Box>
      </Box>
    </Box>
  )
}