import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
// import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
// import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../utils/authConfig';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
import Navigator from '../components/Navigator';
import Header from '../components/Header';
import HeaderUnauth from '../components/HeaderUnauth';
import Content from '../pages/Content';
import BarcodeReader from '../pages/BarcodeReader';
import Freeword from '../pages/Freeword';
import ItemList from '../pages/ItemList';
import Plan from '../pages/Plan';
import Profile from '../pages/Profile';
import Feedback from '../pages/Feedback';
import { site } from '../utils/constant';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      {/* <Link color="inherit" href="https://mui.com/"> */}
      {site}
      {/* </Link>{' '} */}
      {new Date().getFullYear()}.
    </Typography>
  );
}

let theme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          // backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;

export default function Paperbase() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const { instance } = useMsal();
  //   const authRequest = {
  //     ...loginRequest
  // };

  /**
   * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated 
   * or attempt to sign a user in. Just provide it with the interaction type you would like to use 
   * (redirect or popup) and optionally a [request object](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md)
   * to be passed to the login API, a component to display while authentication is in progress or a component to display if an error occurs. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
   */
  return (
    <ThemeProvider theme={theme}>
      {/* <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect} 
        authenticationRequest={authRequest}
      > */}
      <AuthenticatedTemplate>
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
          <CssBaseline />
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            {isSmUp ? null : (
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                isSmUp={isSmUp}
              />
            )}

            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="permanent"
              isSmUp={isSmUp}
              sx={{ display: { sm: 'block', xs: 'none' } }}
            />
          </Box>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Header onDrawerToggle={handleDrawerToggle} />
            <Box component="main" sx={{ flex: 1, py: 6, px: 2, bgcolor: '#eaeff1' }}>
              <Routes>
                <Route index element={<Content />} />
                <Route path="barcode" element={<BarcodeReader />} />
                <Route path="freeword" element={<Freeword />} />
                <Route path="itemlist" element={<ItemList />} />
                <Route path="profile" element={<Profile />} />
                <Route path="plan" element={<Plan />} />
                <Route path="feedback" element={<Feedback />} />
              </Routes>
            </Box>
            <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
              <Copyright />
            </Box>
          </Box>
        </Box>
      </AuthenticatedTemplate>
      {/* </MsalAuthenticationTemplate> */}
      <UnauthenticatedTemplate>
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
          <CssBaseline />
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <HeaderUnauth />
            <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
              <Box mb={1} display="flex" justifyContent="center">
                <FormGroup>
                  <FormControlLabel control={
                    <Checkbox checked={checked} onChange={handleChangeChecked} />
                  } label={<span><a href="https://www.kakakuchosa.eeuapps.com/tos" target="_blank" rel="noopener noreferrer">利用規約</a>に同意する</span>} />               
                </FormGroup>
              </Box>
              <Box mb={5}>
                <Button variant="contained" sx={{ mr: 1 }} onClick={() => instance.loginRedirect(loginRequest)} disabled={!checked}>
                  サインアップ / サインイン
                </Button>
              </Box>
              <Box>
                <Typography variant="h6" align="center">推奨環境</Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <dl>
                  <dt>対応ブラウザ</dt>
                  <dd>Google Chrome, Safari</dd>
                  <dt>画面解像度</dt>
                  <dd>1,334 x 750 以上</dd>
                  <dt>カメラ画素数</dt>
                  <dd>1,200万画素 以上</dd>
                </dl>
              </Box>
            </Box>
            <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
              <Copyright />
            </Box>
          </Box>
        </Box>
      </UnauthenticatedTemplate>
    </ThemeProvider>
  );
}
