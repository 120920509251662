import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FreewordContent from '../components/FreewordContent';

export default function Freeword() {

  return (
    <>
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
        <Box pt={2} pb={2}>
          <Box mb={1}>
            <Typography component="h5" variant="h5">フリーワード検索</Typography>
          </Box>
          <FreewordContent open={false} page={'reader'} />
        </Box>
      </Paper>
    </>
  );
}
