import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import ItemPaper from '../components/ItemPaper';
import BasicModal from '../components/BasicModal';
import BasicModalFreeword from '../components/BasicModalFreeword';

import { useMsal, useAccount } from '@azure/msal-react';
// import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources } from '../utils/authConfig';
import { callApiWithToken } from '../utils/fetch';

export default function ItemList() {
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([]);
  const [params, setParams] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [openFreeword, setOpenFreeword] = React.useState(false);
  const [result, setResult] = React.useState('');

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const perPage = 6;
  const [page, setPage] = React.useState(1);

  const reloadItems = async () => {
    setLoading(true);
    const options = {
      method: 'POST',
    };

    if (account && inProgress === 'none') {
      instance.acquireTokenSilent({
          scopes: protectedResources.apiHello.scopes,
          account: account
      }).then((response) => {
          callApiWithToken(response.accessToken, '/api/list-items', options)
            .then((data) => {
              setItems(data);
              setPage(1);
              setLoading(false);
            }).catch((error) => {
              console.error('Error:', error);
              alert('商品が見つかりません');
            });
      }).catch((error) => {
        console.error('Error:', error);
        alert('認証失敗、再度サインインしてください');
      });
    }
  }

  const handleOpen = (type, params, jancode) => {
    setParams(params);
    if (type === 'jancode') {
      setResult(jancode);
      setOpen(true);
    } else if (type === 'freeword') {
      setOpenFreeword(true);
    } else {
      console.error('Error:type不正');
    }
    
  };
  const handleClose = () => setOpen(false);

  const handleCloseFreeword = () => setOpenFreeword(false);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const deleteItem = (name, params) => {
    const ret = window.confirm(`${name} を削除しますか？`)
    if (!ret) {
        return false
    } else {
      setLoading(true);

      const options = {
        method: 'POST',
        body: JSON.stringify({
          params: params
        }),
      };
  
      if (account && inProgress === 'none') {
        instance.acquireTokenSilent({
            scopes: protectedResources.apiHello.scopes,
            account: account
        }).then((response) => {
            callApiWithToken(response.accessToken, '/api/delete-item', options)
              .then(async () => {
                await reloadItems();
                alert('削除成功');
                setLoading(false);
              }).catch((error) => {
                console.error('Error:', error);
                alert('削除失敗');
                setLoading(false);
              });
        }).catch((error) => {
          console.error('Error:', error);
          alert('認証失敗、再度サインインしてください');
          setLoading(false);
        });
      }
    }
  }

  React.useEffect(() => {
    reloadItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box mb={2}>
        <Typography component="h5" variant="h5">商品一覧</Typography>
      </Box>
      {loading ?
        <Box display="flex" justifyContent="center"> 
          <CircularProgress />
        </Box>
      :
        <>
          {items && items.slice((page - 1) * perPage, (page - 1) * perPage + perPage).map((item, idx) =>
            <Box mb={2} key={idx}>
              <ItemPaper item={item} handleOpen={handleOpen} deleteItem={deleteItem} />
            </Box>
          )}
          <Box display="flex" justifyContent="center"> 
            <Pagination
              color="primary"
              count={items && Math.ceil(items.length / perPage)}
              onChange={handleChangePage}
              page={page}
              size="small"
            />
          </Box>
        </>
      }
      <BasicModal open={open} page={'list'} result={result} params={params} handleClose={handleClose} reloadItems={reloadItems} />
      <BasicModalFreeword open={openFreeword} page={'list'} params={params} handleClose={handleCloseFreeword} reloadItems={reloadItems} />
    </>
  );
}
