import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import { servNames, sorts } from '../utils/constant';

export default function SearchResult(props) {
  const { items, serv, sort } = props;
  return (
    <>
      <Box display="flex" justifyContent="flex-start">
        <Typography color="text.secondary">
          {`サイト: ${serv !== '' ? servNames.find((obj) => obj.serv === serv).str : ''}`}
        </Typography>
      </Box>
      <Box mb={1} display="flex" justifyContent="flex-start">
        <Typography color="text.secondary">
          {`並び順: ${sorts.find((obj) => obj.value === sort).name}`}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {items.map((item, idx) =>
            <Box key={idx}>
              <ListItem>
                <ListItemButton alignItems="flex-start" onClick={() => window.open(item.itemUrl)}>
                  <ListItemAvatar>
                    <Avatar
                      alt="Remy Sharp"
                      src={item.imageUrl}
                      variant="square"
                      sx={{ width: 56, height: 56, marginRight: 2 }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`￥ ${item.itemPrice.toLocaleString()}`}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.secondary"
                        >
                          {item.itemName}
                        </Typography>
                        <br />
                        <Box>
                          <Typography
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                            }}
                            color="text.secondary"
                            component="span"
                            variant="body2"
                          >
                            <HomeIcon />
                            {item.shopName}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider variant="inset" component="li" />
            </Box>
          )}
        </List>
      </Box>
    </>
  )
}