import { baseUrls, paramPref, onSaleParamStrs, sortParamStrs } from "./constant";

export const openNewWindows = (serv, keyword, onSale, sort) => {
  let url = '';
  const paramStrArr = [];

  url += baseUrls.find((obj) => obj.serv === serv).str;
  url += encodeURIComponent(keyword);

  if (onSale) {
    paramStrArr.push(onSaleParamStrs.find((obj) => obj.serv === serv).str);
  }

  if (sort !== '0') {
    const filteredSortParamStrs = sortParamStrs.find((obj) => obj.serv === serv);
    paramStrArr.push(filteredSortParamStrs.strs.find((obj) => obj.sort === sort).str);
  }

  if (paramStrArr.length > 0) {
    url += paramPref.find((obj) => obj.serv === serv).str;
    url += paramStrArr.join('&');
  }

  if (url === '') {
    console.error('Error:リクエスト不正');
    return;
  }
  window.open(url);
}
