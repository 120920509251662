import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AbcIcon from '@mui/icons-material/Abc';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';

export default function ItemPaper(props) {
  const { item, handleOpen, deleteItem } = props;

  return (
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              {item.type === "jancode" ? <CameraAltIcon color="inherit" sx={{ display: 'block' }} /> : <AbcIcon color="inherit" sx={{ display: 'block' }} />}
            </Grid>
            <Grid item xs>
              <Typography>{item.name}</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box p={1}>
        <Box mb={1} display="flex" justifyContent="center">
          <Box mr={1}><label>検索方法：</label></Box>
          <Box mr={1}><Typography>{item.type === "jancode" ? "バーコード" : "フリーワード"}</Typography></Box>
        </Box>
        {item.type === "jancode" &&
          <Box mb={1} display="flex" justifyContent="center">
            <Box mr={1}><label>JANコード：</label></Box>
            <Box mr={1}><Typography>{item.jancode}</Typography></Box>
          </Box>
        }
        <Box mb={1} display="flex" justifyContent="center">
          <Box mr={1}><label>更新日時：</label></Box>
          <Box mr={1}><Typography>{new Date(item.updated_at * 1000).toLocaleString()}</Typography></Box>
        </Box>
        <Box mb={1} display="flex" justifyContent="center">
          <Button variant="contained" color="error" sx={{ mr: 1 }} startIcon={<DeleteIcon />} onClick={() => deleteItem(item.name, { id: item.id, partition_key: item.partition_key })}>
            削除
          </Button>
          <Button variant="contained" sx={{ mr: 1 }} startIcon={<ViewCarouselIcon />} onClick={() => handleOpen(item.type, { id: item.id, partition_key: item.partition_key }, item.type === "jancode" ? item.jancode : null)}>
            開く
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
