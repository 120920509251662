import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import { site, from, categories } from '../utils/constant';

import { useMsal, useAccount } from '@azure/msal-react';
// import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources } from '../utils/authConfig';
import useCurrentUser from '../utils/useCurrentUser';
import { callApiWithToken } from '../utils/fetch';

export default function Feedback() {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({
    familyName: '',
    givenName: '',
    id: '',
    email: '',
    category: '0',
    content: '',
  });

  const user = useCurrentUser();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const send = async (event) => {
    setLoading(true);
    event.preventDefault()

    const category = categories.find((c) => c.value === data.category);

    const options = {
      method: 'POST',
      body: JSON.stringify({
        site: site,
        from: from,
        name: `${data.familyName} ${data.givenName}(${data.id})`,
        email: data.email,
        content: `[カテゴリ: ${category.name}(${data.category})]\n${data.content}`,
      }),
    };

    if (account && inProgress === 'none') {
      instance.acquireTokenSilent({
          scopes: protectedResources.apiHello.scopes,
          account: account
      }).then((response) => {
          callApiWithToken(response.accessToken, '/eapi/send', options)
            .then((response) => {
              if (response.content.length > 0) {
                alert('送信が完了しました！追ってご連絡します！');
                setData({
                  ...data,
                  category: '0',
                  content: '',
                });
                setLoading(false);
              }
            }).catch((error) => {
              console.error('Error:', error);
              alert('送信失敗');
              setLoading(false);
            });
      }).catch((error) => {
        console.error('Error:', error);
        alert('認証失敗、再度サインインしてください');
        setLoading(false);
      });
    }
  };

  React.useEffect(() => {
    setLoading(true);
    setData({
      familyName: user.familyName,
      givenName: user.givenName,
      id: user.sub,
      email: user.username,
      category: '0',
      content: '',
    });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
        {loading ?
          <Box display="flex" justifyContent="center"> 
            <CircularProgress />
          </Box>
        :
          <Box p={2}>
            <Box mb={2}>
              <Typography component="h5" variant="h5">フィードバック</Typography>
              <Typography color="text.secondary">
                皆様のご意見をお聞かせください！
              </Typography>
            </Box>
            <Box mb={2} display="flex" justifyContent="center">
              <TextField id="outlined-basic" name="familyName" label="姓" variant="outlined" value={data.familyName} disabled />
            </Box>
            <Box mb={2} display="flex" justifyContent="center">
              <TextField id="outlined-basic" name="givenName" label="名" variant="outlined" value={data.givenName} disabled />
            </Box>
            <Box mb={2} display="flex" justifyContent="center">
              <TextField id="outlined-basic" name="email" label="メールアドレス" variant="outlined" type={'email'} value={data.email} disabled />
            </Box>
            <Box mb={2}><Link to="/profile">変更</Link></Box>
            <Box mb={2} display="flex" justifyContent="center">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">カテゴリ</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.category}
                  name="category"
                  label="カテゴリ"
                  onChange={handleChange}
                >
                  {categories.map((c, idx) => <MenuItem value={c.value} key={idx}>{c.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Box>
            <Box mb={1} display="flex" justifyContent="center">
              <TextField
                id="outlined-multiline-static"
                label="内容"
                multiline
                rows={4}
                fullWidth
                value={data.content}
                name="content"
                onChange={handleChange}
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <Button variant="contained" startIcon={<SendIcon />} onClick={send}>送信</Button>
            </Box>
          </Box>
        }
      </Paper>
    </>
  );
}
