import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// import TextField from '@mui/material/TextField';
// import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@mui/material/IconButton';
// import SearchIcon from '@mui/icons-material/Search';
// import RefreshIcon from '@mui/icons-material/Refresh';
import useCurrentUser from '../utils/useCurrentUser';

export default function Content() {
  const [loading, setLoading] = React.useState(true);
  const [greeting, setGreeting] = React.useState('');
  const user = useCurrentUser();

  React.useEffect(() => {
    setLoading(true);
    // 時間帯によってあいさつを変える
    const hours = new Date().getHours();
    switch (true) {
      case (6 <= hours && hours <= 11):
        setGreeting('おはようございます');
        break;
      case (12 <= hours && hours <= 17):
        setGreeting('こんにちは');
        break;
      default:
        setGreeting('こんばんは');
        break;
    }

    setLoading(false);
  }, []);
  return (
    <>
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              {/* <Grid item>
                <SearchIcon color="inherit" sx={{ display: 'block' }} />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="検索ワード"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: 'default' },
                  }}
                  variant="standard"
                />
              </Grid> */}
              <Grid item>
                {/* <Button variant="contained" sx={{ mr: 1 }}>
                  ボタン
                </Button> */}
                <span>トップページ</span>
                {/* <Tooltip title="Reload">
                  <IconButton>
                    <RefreshIcon color="inherit" sx={{ display: 'block' }} />
                  </IconButton>
                </Tooltip> */}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {loading ?
          <Box display="flex" justifyContent="center"> 
            <CircularProgress />
          </Box>
        :
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
            {`${greeting}、 ${user?.name} さん！`}
          </Typography>
        }
      </Paper>
    </>
  );
}
