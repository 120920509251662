import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
// import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
// import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
// import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import Tab from '@mui/material/Tab';
// import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
// import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';
import CampaignIcon from '@mui/icons-material/Campaign';

import { useMsal, useAccount } from '@azure/msal-react';
// import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources } from '../utils/authConfig';
import useCurrentUser from '../utils/useCurrentUser';

// import { plans } from '../utils/constant';
import { callApiWithToken } from '../utils/fetch';
import { site } from '../utils/constant';

// const lightColor = 'rgba(255, 255, 255, 0.7)';

function Header(props) {
  const { onDrawerToggle } = props;

  const navigate = useNavigate();
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElProf, setAnchorElProf] = React.useState(null);
  // const [plan, setPlan] = React.useState(null);
  const [news, setNews] = React.useState([]);
  const open = Boolean(anchorEl);
  const openProf = Boolean(anchorElProf);
  
  const user = useCurrentUser();
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProf = (event) => {
    setAnchorElProf(event.currentTarget);
  };
  const handleCloseProf = () => {
    setAnchorElProf(null);
  };

  React.useEffect(() => {
    const options = {
      method: 'POST',
    };

    if (account && inProgress === 'none') {
      instance.acquireTokenSilent({
          scopes: protectedResources.apiHello.scopes,
          account: account
      }).then((response) => {
          callApiWithToken(response.accessToken, '/api/create-user', options)
            .then((response) => {
              // const planObj = plans.find((p) => p.id === response.user.plan);
              // setPlan(planObj.chip);
              setNews(response.news);
            }).catch((error) => {
              console.error('Error:', error);
              alert('ユーザ取得失敗');
            });
      }).catch((error) => {
        console.error('Error:', error);
        alert('認証失敗、再度サインインしてください');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            {/* <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
            <Grid item xs />
            <Grid item>
              <Typography color="inherit" variant="h5" component="h1">
                {site}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Link
                href="/"
                variant="body2"
                sx={{
                  textDecoration: 'none',
                  color: lightColor,
                  '&:hover': {
                    color: 'common.white',
                  },
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Go to docs
              </Link>
            </Grid> */}
            <Grid item xs />
            <Grid item>
              {/* <Tooltip title="Alerts • No alerts"> */}
              <IconButton
                color="inherit"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <Badge badgeContent={news && news.length} color="error"><NotificationsIcon /></Badge>
              </IconButton>
              {/* </Tooltip> */}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuList>
                  {news && news.map((n, idx) => 
                    <div key={idx}>
                      <MenuItem style={{ whiteSpace: 'normal' }}>
                        <ListItemIcon>
                          {n.type === 'camp' ?
                            <CampaignIcon fontSize="small" />
                          :
                            <InfoIcon fontSize="small" />
                          }
                        </ListItemIcon>
                        <ListItemText>{`(${new Date(n.updated_at * 1000).toLocaleDateString()}) ${n.text}`}</ListItemText>
                      </MenuItem>
                      <Divider />
                    </div>
                  )}
                </MenuList>
              </Menu>
            </Grid>
            <Grid item>
              <IconButton color="inherit" sx={{ p: 0.5 }} onClick={handleClickProf}>
                <Avatar src="" alt="My Avatar">{user?.name.slice(0,1)}</Avatar>
              </IconButton>
              <Menu
                anchorEl={anchorElProf}
                id="account-menu"
                open={openProf}
                onClose={handleCloseProf}
                onClick={handleCloseProf}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <Box p={1}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography color="text.secondary" style={{ lineHeight: '32px' }}>{user?.name}</Typography>
                    {/* <Box display="flex" justifyContent="flex-end">{plan}</Box> */}
                  </Box>
                  <Box display="flex" justifyContent="flex-start">
                    <Typography color="text.secondary" style={{ lineHeight: '32px' }}>{user?.username}</Typography>
                  </Box>
                </Box>
                <Divider />
                <MenuItem onClick={() => navigate('/profile')}>
                  <ListItemIcon>
                    <AccountCircleIcon fontSize="small" />
                  </ListItemIcon>
                  プロフィール
                </MenuItem>
                <MenuItem onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: '/' })}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  サインアウト
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/* <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>         
            <Grid item>
              <Button
                sx={{ borderColor: lightColor }}
                variant="outlined"
                color="inherit"
                size="small"
              >
                Web setup
              </Button>
            </Grid>     
          </Grid>
        </Toolbar>
      </AppBar> */}
      {/* <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>
        <Tabs value={0} textColor="inherit">
          <Tab label="Users" />
          <Tab label="Sign-in method" />
          <Tab label="Templates" />
          <Tab label="Usage" />
        </Tabs>
      </AppBar> */}
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
