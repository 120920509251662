import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';

import { useMsal, useAccount } from '@azure/msal-react';
// import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { protectedResources } from '../utils/authConfig';
import { callApiWithToken } from '../utils/fetch';
import ProfitCalculator from './ProfitCalculator';
import SearchResult from './SearchResult';
import SearchPriceForm from './SearchPriceForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  borderRadius: '8px',
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicModal(props) {
  const { open, page, result, params, handleClose, reloadItems } = props;

  const [name, setName] = useState('');
  const [nameItems, setNameItems] = useState([]);
  const [joinedName, setJoinedName] = useState('');
  const [memo, setMemo] = useState('');
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [amounts, setAmounts] = useState({
    selling: 0,
    // cost: 0,
    postage: 0,
    chargeRate: 10,
    charge: 0,
    other: 0,
    profit: 0,
    percent: 0,
  });
  const [items, setItems] = useState([]);
  const [serv, setServ] = useState('');
  const [onSale, setOnSale] = useState(false);
  const [sort, setSort] = useState('0');

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const searchItem = () => {
    const param = { jancode : result };
    const query = new URLSearchParams(param);

    /**
    * useMsal is hook that returns the PublicClientApplication instance, 
    * an array of all accounts currently signed in and an inProgress value 
    * that tells you what msal is currently doing. For more, visit: 
    * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
    */
    const options = {
      method: 'GET',
    };
    if (account && inProgress === 'none') {
      instance.acquireTokenSilent({
          scopes: protectedResources.apiHello.scopes,
          account: account
      }).then((response) => {
          callApiWithToken(response.accessToken, `/api/search-item?${query}`, options)
            .then((data) => {
              if (data.name) {
                setName(data.name);
                setNameItems(data.nameItems);
              } else {
                alert('商品が見つかりません');
              }
              setLoading(false);
            }).catch((error) => {
              console.error('Error:', error);
              alert('商品情報取得失敗(JAN)');
              setLoading(false);
            });
      }).catch((error) => {
          // in case if silent token acquisition fails, fallback to an interactive method
          // if (error instanceof InteractionRequiredAuthError) {
          //     if (account && inProgress === 'none') {
          //         instance.acquireTokenPopup({
          //             scopes: protectedResources.apiHello.scopes,
          //         }).then((response) => {
          //             callApiWithToken(response.accessToken, protectedResources.apiHello.endpoint)
          //                 .then(response => {return response;});
          //         }).catch(error => console.log(error));
          //     }
          // }
          console.error('Error:', error);
          alert('認証失敗、再度サインインしてください');
          setLoading(false);
      });
    }
  }

  const research = () => {
    if (open) {
      setLoading(true);
      searchItem();
    } else {
      console.error('Error:リクエスト不正');
    }
  };

  const handleChangeChecked = (event) => {
    const nameItemsTmp = [];
    const joinedNameTmp = [];
    nameItems.forEach((nameItem) => {
      if (String(nameItem.idx) === String(event.target.name)) { 
        if (event.target.checked) {
          joinedNameTmp.push(nameItem.name_str);
        }
        nameItemsTmp.push({ ...nameItem, checked: event.target.checked });
      } else {
        if (nameItem.checked) {
          joinedNameTmp.push(nameItem.name_str);
        }
        nameItemsTmp.push(nameItem);
      }
    })
    
    setNameItems(nameItemsTmp);
    setJoinedName(joinedNameTmp.join(' '));
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const saveItem = () => {
    setLoading(true);
    const options = {
      method: 'POST',
      body: JSON.stringify({
        page: page,
        params: params,
        data: {
          jancode: result,
          name: name,
          nameItems: nameItems,
          memo: memo,
          amounts: amounts,
        },
      }),
    };

    if (account && inProgress === 'none') {
      instance.acquireTokenSilent({
          scopes: protectedResources.apiHello.scopes,
          account: account
      }).then((response) => {
          callApiWithToken(response.accessToken, '/api/save-item-jancode', options)
            .then(async (data) => {
              if (page === 'list') {
                await reloadItems();
              }
              setName(data.name);
              setNameItems(data.name_items);
              setMemo(data.memo);
              setAmounts(data.amounts);
              alert('保存成功');
              setLoading(false);
            }).catch((error) => {
              console.error('Error:', error);
              alert('保存失敗');
              setLoading(false);
            });
      }).catch((error) => {
        console.error('Error:', error);
        alert('認証失敗、再度サインインしてください');
        setLoading(false);
      });
    }
  }

  useEffect(() => {
    if (open) {
      setLoading(true);

      if(page === 'reader') {
        searchItem();
      } else if(page === 'list') {
        const options = {
          method: 'POST',
          body: JSON.stringify(params),
        };
    
        if (account && inProgress === 'none') {
          instance.acquireTokenSilent({
              scopes: protectedResources.apiHello.scopes,
              account: account
          }).then((response) => {
              callApiWithToken(response.accessToken, '/api/item-info', options)
                .then((data) => {
                  setName(data.name);
                  setNameItems(data.name_items);
                  setMemo(data.memo);
                  setAmounts(data.amounts);
                  const joinedNameTmp = [];
                  data.name_items.forEach((nameItem) => {
                    if (nameItem.checked) {
                      joinedNameTmp.push(nameItem.name_str);
                    }
                  });
                  setJoinedName(joinedNameTmp.join(' '));
                  setLoading(false);
                }).catch((error) => {
                  console.error('Error:', error);
                  alert('商品情報取得失敗(DB)');
                  setLoading(false);
                });
          }).catch((error) => {
            console.error('Error:', error);
            alert('認証失敗、再度サインインしてください');
            setLoading(false);
          });
        }
      } else {
        console.error('Error:page不正');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" justifyContent="flex-end">
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            価格検索
          </Typography> */}
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Box>
        {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </Typography> */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChangeTabs} aria-label="basic tabs example">
            <Tab label="価格検索" {...a11yProps(0)} />
            <Tab label="検索結果" {...a11yProps(1)} />
            <Tab label="利益計算" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} style={{ maxHeight: '350px', overflow: 'scroll' }}>
          <Box mb={1} display="flex" justifyContent="center">
            <TextField id="outlined-basic" label="JANコード" variant="outlined" value={result} disabled />
          </Box>
          {loading ?
            <Box display="flex" justifyContent="center"> 
              <CircularProgress />
            </Box>
          :
            <>
              {name &&
                <>
                  <Box display="flex" justifyContent="center">
                    <Typography>{name}</Typography>
                    <IconButton aria-label="delete" onClick={research}>
                      <RefreshIcon />
                    </IconButton>
                  </Box>
                  {nameItems &&
                    <Box mb={1}>
                      <Divider />
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">検索ワード</FormLabel>
                        <FormGroup>
                          {nameItems.map((nameItem) => <FormControlLabel control={
                            <Checkbox checked={nameItem.checked} onChange={handleChangeChecked} name={nameItem.idx}/>
                          } label={nameItem.name_str} key={nameItem.idx} />)}               
                        </FormGroup>
                      </FormControl>
                      <Divider />
                    </Box>
                  }
                  <SearchPriceForm keyword={joinedName} sort={sort} onSale={onSale} setLoading={setLoading} setItems={setItems} setServ={setServ} setValue={setValue} setSort={setSort} setOnSale={setOnSale} />
                </>
              }
            </>
          }
        </TabPanel>
        <TabPanel value={value} index={1} style={{ maxHeight: '350px', overflow: 'scroll' }}>
          {loading ?
              <Box display="flex" justifyContent="center"> 
                <CircularProgress />
              </Box>
            :
              <SearchResult items={items} serv={serv} sort={sort} />
          }
        </TabPanel>
        <TabPanel value={value} index={2} style={{ maxHeight: '350px', overflow: 'scroll' }}>
          {loading ?
              <Box display="flex" justifyContent="center"> 
                <CircularProgress />
              </Box>
            :
              <ProfitCalculator amounts={amounts} memo={memo} setAmounts={setAmounts} setMemo={setMemo} />
          }
        </TabPanel>
        {(!loading && name && value !== 1) &&
          <>
            <Divider />
            <Box mt={1} display="flex" justifyContent="center"><Button variant="contained" startIcon={<SaveIcon />} onClick={saveItem}>保存</Button></Box>
          </>
        }
      </Box>
    </Modal>
  );
}
