import React from 'react';
// import { Link } from 'react-router-dom';
// import { useMsal, useAccount } from '@azure/msal-react';
// import { b2cPolicies } from '../utils/authConfig';
// import { protectedResources } from '../utils/authConfig';
import useCurrentUser from '../utils/useCurrentUser';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
// import FormLabel from '@mui/material/FormLabel';
import Paper from '@mui/material/Paper';
// import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import { plans } from '../utils/constant';
// import { callApiWithToken } from '../utils/fetch';

export default function Profile() {
  // const [loading, setLoading] = React.useState(true);
  const [loading] = React.useState(false);
  // const [data, setData] = React.useState({
  //   id: '',
  //   phone: '',
  //   partition_key: '',
  // });
  // const [plan, setPlan] = React.useState();

  const user = useCurrentUser();
  // const { instance, accounts, inProgress } = useMsal();
  // const account = useAccount(accounts[0] || {});

  // const handleChange = (event) => {
  //   setData({
  //     ...data,
  //     [event.target.name]: event.target.value
  //   });
  // };

  // const updateUser = () => {
  //   setLoading(true);
  //   fetch('/api/update-user', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(data),
  //   })
  //   .then((response) => response.json())
  //   .then((json) => {
  //     setData({
  //       id: json.id,
  //       phone: json.phone,
  //       partition_key: json.partition_key,
  //     });
  //     const planObj = plans.find((p) => p.id === json.plan);
  //     setPlan(planObj.chip);
  //     alert('更新成功');
  //     setLoading(false);
  //   })
  //   .catch((error) => {
  //     console.error('Error:', error);
  //     alert('更新失敗');
  //     setLoading(false);
  //   });
  // };

  // React.useEffect(() => {
  //   setLoading(true);
  //   const options = {
  //     method: 'POST',
  //   };

  //   if (account && inProgress === 'none') {
  //     instance.acquireTokenSilent({
  //         scopes: protectedResources.apiHello.scopes,
  //         account: account
  //     }).then((response) => {
  //         callApiWithToken(response.accessToken, '/api/user-info', options)
  //           .then((response) => {
  //             const planObj = plans.find((p) => p.id === response.plan);
  //             setPlan(planObj.chip);
  //             setLoading(false);
  //           }).catch((error) => {
  //             console.error('Error:', error);
  //             alert('ユーザ取得失敗');
  //           });
  //     }).catch((error) => {
  //       console.error('Error:', error);
  //       alert('認証失敗、再度サインインしてください');
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
        <Box p={2}>
          <Box mb={2}>
            <Typography component="h5" variant="h5">プロフィール</Typography>
          </Box>
          {loading ?
            <Box display="flex" justifyContent="center"> 
              <CircularProgress />
            </Box>
          :
            <>
              <Box mb={2} display="flex" justifyContent="center">
                <TextField id="outlined-basic" name="name" label="表示名" variant="outlined" value={user?.name} disabled />
              </Box>
              <Box mb={2} display="flex" justifyContent="center">
                <TextField id="outlined-basic" name="familyName" label="姓" variant="outlined" value={user?.familyName} disabled />
              </Box>
              <Box mb={2} display="flex" justifyContent="center">
                <TextField id="outlined-basic" name="givenName" label="名" variant="outlined" value={user?.givenName} disabled />
              </Box>
              <Box mb={2} display="flex" justifyContent="center">
                <TextField id="outlined-basic" name="userName" label="メールアドレス" variant="outlined" type={'email'} value={user?.username} disabled />
              </Box>
              <Box mb={2} display="flex" justifyContent="center">
                {/* <Button variant="contained" startIcon={<OpenInNewIcon />} onClick={() => instance.loginPopup(b2cPolicies.authorities.editProfile)}>編集</Button> */}
                <a href="https://kakakuchosa.b2clogin.com/kakakuchosa.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_pe&client_id=ea1ca8a6-d2b4-4a03-86fd-624de2b2d892&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp.kakakuchosa.eeuapps.com&scope=openid&response_type=code&prompt=login&code_challenge_method=S256&code_challenge=AcYr29nBaK00bTRa9CTuN-HvoKkzik7WJs1uWWiqjn8">編集</a>
              </Box>
              {/* <Box display="flex" justifyContent="center">
                <Box mr={1}><FormLabel component="legend" style={{ lineHeight: '32px' }}>プラン</FormLabel></Box>
                <Box mr={1}>{plan}</Box>
                <Box mr={1}><Link to="/plan" style={{ lineHeight: '32px' }}>変更</Link></Box>
              </Box> */}
            </>
          }
        </Box>
      </Paper>
    </>
  );
}
