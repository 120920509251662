import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { plans } from '../utils/constant';

export default function Plan() {
  return (
    <>
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h4"
          variant="h4"
          align="center"
          color="text.primary"
          gutterBottom
        >
          プラン
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          利用量に応じてプランをお選びいただけます
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {plans.map((plan) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={plan.id}
              xs={12}
              sm={plan.id === 'premium' ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={plan.id.toUpperCase()}
                  subheader={plan.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={plan.star ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    // backgroundColor: (theme) =>
                    //   theme.palette.mode === 'light'
                    //     ? theme.palette.grey[100]
                    //     : theme.palette.grey[700],
                    backgroundColor: (theme) =>
                    plan.id !== 'free' ? theme.palette.grey[700] : theme.palette.grey[100],
                  }}
                />
                <CardContent
                  sx={{
                    height: '220px',
                    backgroundColor: (theme) =>
                      plan.id !== 'free' && theme.palette.grey[600],
                  }}
                >
                  {plan.id === 'free' ?
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'baseline',
                          mb: 2,
                        }}
                      >
                        <Typography component="h2" variant="h3" color="text.primary">
                          ￥{plan.price}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                          /月
                        </Typography>
                      </Box>
                      <ul>
                        {plan.description.map((line) => (
                          <Typography
                            component="li"
                            variant="subtitle1"
                            align="center"
                            key={line}
                            style={{ fontSize: '14px' }}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </>
                  :
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <Typography component="h6" variant="h6" color="white" style={{ lineHeight: '190px' }}>
                        COMING SOON !
                      </Typography>
                    </Box>
                  }
                </CardContent>
                <CardActions
                  sx={{
                    backgroundColor: (theme) =>
                      plan.id !== 'free' && theme.palette.grey[600],
                  }}
                >
                  {plan.id !== 'free' ?
                    <Button fullWidth variant={plan.buttonVariant}>
                      {plan.buttonText}
                    </Button>
                  :
                    <Button fullWidth variant={plan.buttonVariant} disabled>
                      利用中
                    </Button>
                  }
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
